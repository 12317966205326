import React from 'react';

class Giffy extends React.Component {
  state = {};
  render() {
    return (
      <section className="content-section callout vh-100" id="giffy">
      </section>
    )
  }
}

export default Giffy;